<script>
  /**
   * An item inside a `BaseList` component.
   */
  export default {
    inject: {
      list: 'BaseList',
    },
    props: {
      /** Whether to put emphasis on this list item, which makes its text bolder (as well as the checkmark icon thicker, if inside a `BaseList` of type `check`). */
      emphasis: {
        type: Boolean,
        default: false,
      },
      /** Only if inside a `BaseList` of type `check`. The color of the icon. When left `undefined`, the color is inherited from the parent. */
      iconColor: {
        type: String,
        default: undefined,
        validator: (value) => ['primary', 'secondary'].includes(value),
      },
    },
    computed: {
      checkmarkStyle() {
        let marginTop
        switch (this.list.finalLeading) {
          case 'tight':
            marginTop = '0.05em'
            break
          case 'snug':
            marginTop = '0.075em'
            break
          case 'normal':
            marginTop = '0.15em'
            break
        }
        return {
          marginTop,
        }
      },
    },
  }
</script>

<template>
  <li :class="`${emphasis ? 'font-semibold' : ''}`">
    <!-- for purgecss: text-primary text-secondary -->
    <BaseIcon
      v-if="list.type === 'check'"
      :class="`checkmark absolute left-0 top-0 ${iconColor ? `text-${iconColor}` : ''}`"
      :style="checkmarkStyle"
    >
      <IconCheckmarkThick v-if="emphasis" />
      <IconCheckmark v-else />
    </BaseIcon>
    <!-- @slot The list item’s content. Should contain text. -->
    <slot />
    <em v-if="$scopedSlots.fineprint" class="text-gray-opacity68">
      <!-- @slot Optional. Additonal contextual information for the list item. -->
      <slot name="fineprint" />
    </em>
  </li>
</template>

<style lang="postcss" scoped>
  .checkmark {
    width: 1.25em;
    height: 1.25em;
    margin-left: -1.75em;
  }
</style>
